@font-face {
	font-family: 'CFAstyStdBook';
	src: url('../fonts/CFAstyStd-Book.eot');
	src:
		url('../fonts/CFAstyStd-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CFAstyStd-Book.woff') format('woff'),
		url('../fonts/CFAstyStd-Book.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	//text-rendering: optimizeLegibility;
	font-display: swap;
}

@font-face {
	font-family: 'CFAstyStdBold';
	src: url('../fonts/CFAstyStd-Bold.eot');
	src:
		url('../fonts/CFAstyStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CFAstyStd-Bold.woff') format('woff'),
		url('../fonts/CFAstyStd-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	//text-rendering: optimizeLegibility;
	font-display: swap;
}

@font-face {
	font-family: 'CFAstyStdMedium';
	src: url('../fonts/CFAstyStd-Medium.eot');
	src:
		url('../fonts/CFAstyStd-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CFAstyStd-Medium.woff') format('woff'),
		url('../fonts/CFAstyStd-Medium.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	//text-rendering: optimizeLegibility;
	font-display: swap;
}

// @font-face {
//     font-family: 'CFAstyStdLight';
//     src: url('../fonts/CFAstyStd-Light.eot'); /* IE9 Compat Modes */
//     src: url('../fonts/CFAstyStd-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//     url('../fonts/CFAstyStd-Light.woff') format('woff'), /* Modern Browsers */
//     url('../fonts/CFAstyStd-Light.ttf') format('truetype'); /* Safari, Android, iOS */
//     font-style: normal;
//     font-weight: normal;
//     text-rendering: optimizeLegibility;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'CFAstyStdMed';
//     src: url('../fonts/CFAstyStd-Medium.eot'); /* IE9 Compat Modes */
//     src: url('../fonts/CFAstyStd-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//     url('../fonts/CFAstyStd-Medium.woff') format('woff'), /* Modern Browsers */
//     url('../fonts/CFAstyStd-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
//     font-style: normal;
//     font-weight: normal;
//     text-rendering: optimizeLegibility;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'CFAstyStdBlack';
//     src: url('../fonts/CFAstyStd-Black.eot'); /* IE9 Compat Modes */
//     src: url('../fonts/CFAstyStd-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//     url('../fonts/CFAstyStd-Black.woff') format('woff'), /* Modern Browsers */
//     url('../fonts/CFAstyStd-Black.ttf') format('truetype'); /* Safari, Android, iOS */
//     font-style: normal;
//     font-weight: normal;
//     text-rendering: optimizeLegibility;
//     font-display: swap;
// }

$bold-font: 'CFAstyStdBold';
$medium-font: 'CFAstyStdMedium';
$book-font: 'CFAstyStdBook';
