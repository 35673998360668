.category-main {
	padding: 60px 0 20px;

	.top-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.room-info {
			font-size: 15px;
			color: $dark-grey;
			text-align: right;

			div {
				margin-bottom: 6px;

				span {
					font-family: $medium-font;
					width: 60px;
					display: inline-block;
					margin-left: 10px;
					text-align: left;
				}
			}
		}
	}

	.filters-outer {
		padding: 15px 0 30px;
		display: flex;
		align-items: center;

		#gridSortSelect {
			position: relative;
			display: flex;
		}

		.select__placeholder,
		.select__menu {
			font-size: 15px;
		}

		.filters-wrapper {
			display: flex;
			align-items: center;
			margin-left: 70px;

			.label {
				font-size: 15px;
				font-family: $medium-font;
				margin-right: 6px;
			}

			& > div {
				&:not(.label) {
					margin: 0 6px;
					position: relative;
					display: flex;
				}
			}

			.select__control {
				background: $grey-bg;
				border-radius: 3px;
				padding: 6px;
				font-size: 15px;
			}

			.select__multi-value {
				margin: 0 3px;
				background: $white;

				.select__multi-value__label {
					color: $black;
				}
			}

			.filter {
				& > div {
					height: auto;
				}
			}

			.filter {
				&.season {
					.select__menu {
						min-width: 150px;
					}
				}
			}
		}
	}

	.pagination-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		position: relative;

		.custom-pagination {
			display: flex;
			align-items: center;

			.number {
				width: 34px;
				height: 34px;
				border: 0;
				appearance: none;
				background: #8f8e8a;
				margin: 0 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				font-size: 13px;
				cursor: pointer;

				&:hover,
				&.selected {
					background: $orange;
				}

				&.selected {
					cursor: auto;
				}
			}

			.dots {
				width: 34px;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
			}

			.nav-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 34px;
				background: #8f8e8a;
				margin: 0 2px;
				cursor: pointer;

				&:not(.disabled) {
					&:hover {
						background: $orange;
					}
				}

				&.disabled {
					cursor: auto;
					background: $light-grey;
				}
			}

			// .page-wrapper {
			//     margin: 0 10px;
			//     font-size: 15px;
			//     font-family: $medium-font;
			// }
		}

		// .go-to-page-wrapper {
		//     display: flex;
		//     align-items: center;
		//     position: absolute;
		//     right: 0;

		//     .page-input-wrapper {
		//         display: flex;
		//         align-items: center;
		//         font-size: 15px;
		//         font-family: $medium-font;

		//         .page-input {
		//             width: 55px;
		//             margin-left: 5px;
		//             height: 30px;
		//             border-radius: 0;
		//             border: 1px solid $light-grey;
		//             font-size: 14px;
		//             padding: 4px;
		//         }
		//     }

		//     select {
		//         margin-left: 10px;
		//     }
		// }
	}
}
.color-option {
	display: flex;
	align-items: center;
	gap: 8px;

	.color-hex {
		width: 12px;
		height: 12px;
		border: 2px solid #fff;
		outline: 1px solid #cecccc;
	}
}
.root-categories-filter .select__menu {
	text-transform: uppercase;
}
