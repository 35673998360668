.site-header {
	height: $header-height;
	background-color: $dark-grey;
	color: $white;
	display: flex;
	align-items: center;

	.row {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		font-size: 14px;
		margin: 0 -5px;

		@media (max-width: 1300px) {
			font-size: 13px;
		}

		.col {
			padding: 0 5px;
			display: flex;

			&.logout-col {
				align-items: flex-end;
			}
		}

		.logo {
			width: 275px;
			display: block;
			align-self: center;

			@media (max-width: 1600px) {
				width: 200px;
			}

			img {
				display: block;
				height: auto;
			}
		}

		#menuHambWrapper {
			font-size: 14px;
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 4px;
			align-self: center;

			#menuHamb {
				display: flex;
				flex-direction: column;
				margin-right: 6px;

				span {
					width: 14px;
					height: 2px;
					margin: 1px 0;
					display: block;
					background: $white;
					transition: 0.3s;
				}
			}

			.label {
				background: transparent;

				&::selection {
					background: transparent;
				}
			}

			&.show {
				#menuHamb {
					span {
						&:nth-child(1) {
							transform: rotate(45deg) translate(3px);
						}

						&:nth-child(2) {
							transform: rotate(-45deg) translate(3px);
						}
					}
				}
			}

			&:hover {
				.label {
					text-decoration: underline;
				}
			}
		}

		.search-box {
			position: relative;
			width: 350px;
			margin-right: 20px;
			align-self: center;

			@media (max-width: 1600px) {
				width: 290px;
				margin-right: 0;
			}

			.search-field {
				width: 100%;
				height: 34px;
				padding: 8px 25px 6px 5px;
				font-size: 13px;

				@media (min-width: 1600px) {
					padding-left: 15px;
				}

				&::placeholder {
					color: #cecccc;
					font-size: 12px;
				}
			}

			#searchIcon {
				position: absolute;
				right: 10px;
				top: 0;
				bottom: 0;
				margin: auto;
				cursor: pointer;
				z-index: 1;
			}
		}

		.catalog {
			align-self: center;

			.dowload-catalog {
				display: flex;
				align-items: flex-start;
				color: $white;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}

				img {
					margin-right: 5px;
					margin-top: -2px;
				}
			}
		}

		.partner {
			align-self: center;
			max-width: 400px;

			.select__control {
				width: 220px;

				.select__value-container:has(input:focus) {
					visibility: hidden;
				}
			}

			@media (max-width: 1600px) {
				display: flex;
				flex-direction: column;
			}

			#partnerId {
				font-family: $bold-font;
			}
		}

		.customer {
			display: flex;
			align-self: flex-end;
			padding-bottom: 6px;
			justify-content: center;

			.customer-wrapper {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-left: 14px;
				padding-top: 4px;

				@media (max-width: 1300px) {
					margin-left: 5px;
				}

				#customerName {
					font-family: $bold-font;
				}
			}
		}

		.langs {
			align-self: flex-end;
			padding-bottom: 6px;

			.active {
				font-family: $bold-font;
			}
		}

		.cart {
			padding: 4px;
			cursor: pointer;
			align-self: center;

			.cart-wrapper {
				position: relative;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				font-size: 13px;

				#itemsCount {
					color: $orange;
					position: absolute;
					bottom: 6px;
				}
			}
		}

		.logout {
			color: $orange;
			text-decoration: none;
			display: inline-flex;
			align-items: center;
			padding: 5px;

			&:hover {
				text-decoration: underline;
			}

			.icon {
				margin-left: 5px;
				margin-bottom: 3px;
			}
		}
	}
}
