body {
	padding: 0;
	margin: 0;
	font-family: 'CFAstyStdBook';
}

* {
	box-sizing: border-box;
	font-family: 'CFAstyStdBook';
}

strong,
b {
	font-family: $bold-font;
}

img {
	max-width: 100%;
}

.btn {
	cursor: pointer;
	transition: 0.2s;
	padding: 10px 35px;
	line-height: 1;
	font-size: 13px;
	border: 0;
	text-decoration: none;

	&.orange {
		background-color: $orange;
		color: $white;

		&:hover {
			// background-color: $dark-grey;
			// background-color: rgb(240 81 48 / 85%);
			background-color: $black;
		}
	}

	&.black {
		background-color: $black;
		color: $white;

		&:hover {
			background-color: $orange;
		}
	}
}

.container {
	width: 100%;
	max-width: 1710px;
	margin: auto;
	padding: 0 15px;
}

.sr-text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	display: none;
	z-index: 1000;

	&.visible {
		display: block;
	}
}

.pass-input-wrapper {
	position: relative;

	input {
		width: 100%;
		height: 34px;
		padding: 8px;
		margin-bottom: 10px;
		font-size: 13px;
		-webkit-appearance: none;
		border-radius: 0;

		&:focus-visible {
			outline: none;
			border-color: $orange;
		}
	}

	.pass-visibillity-icon {
		width: 26px;
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 4px;
		cursor: pointer;
	}

	#hidePassIcon {
		display: none;
	}
}

input {
	&.error {
		border-color: #ff0000 !important;
	}
}

table {
	text-align: left;
	position: relative;
	border-collapse: collapse;
}

th {
	background: white;
	top: 0; /* Don't forget this, required for the stickiness */
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
	z-index: 99999;
	@media only screen {
		position: sticky;
	}
}
