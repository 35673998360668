$dark-grey: #474747;
$white: #fff;
$orange: #f05130;
$black: #000;
$grey-bg: #f5f5f6;
$border-color: #cecccc;
$light-grey: #8f8e8a;
$out-of-stock: #acacac;
$limited-stock: #e7e6e6;
$available: #fff;
$available-soon: #fce4d6;
$not-available: #fff;
$color-box-border: #707070;
$in-progress: #50cb81;
$completed: #f05130;
$canceled: #cecccc;
