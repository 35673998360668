.login-bg {
	width: 100%;
	height: calc(100vh - $header-height);
	object-fit: cover;
	display: block;
	pointer-events: none;
}

.login-form-container {
	position: absolute;
	bottom: 50%;
	transform: translateY(50%);
	right: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;

	.form-wrapper {
		width: 660px;
		text-align: center;
		color: $white;
	}

	.page-title {
		font-size: 24px;
		// font-family: 'CFAstyStdMedium';
	}

	.page-subtitle {
		max-width: 72%;
		margin: 20px auto;
		font-size: 15px;
	}

	.login-form {
		display: flex;
		flex-direction: column;
		align-items: center;

		input {
			width: 250px;
			height: 34px;
			padding: 8px;
			margin-bottom: 10px;
			font-size: 13px;
			border: 2px solid white;
			background: $white;
		}

		.submit-btn {
			margin: 20px auto 35px;
			font-size: 13px;
			padding: 10px 22px;
			border: 0;
		}
	}

	.forgot-password {
		font-size: 13px;
		color: $white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
