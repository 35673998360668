.saved-orders-wrapper {
	margin-top: 35px;

	.row {
		display: flex;
		margin: 0 -15px;

		.customer-profile-col,
		.saved-orders-col {
			padding: 0 15px;
		}

		.customer-profile-col {
			width: 24%;

			.sub-heading {
				margin-bottom: 24px;
			}

			.customer-info {
				font-size: 15px;

				& > p {
					margin-top: 10px;
					display: flex;
					flex-wrap: wrap;
					line-height: 20px;

					.label {
						font-family: $bold-font;
						margin-right: 5px;
						color: $dark-grey;
					}
				}

				#changePassword {
					margin-top: 30px;
					font-size: 13px;
					text-decoration: underline;
					border: 0;
					appearance: none;
					background: none;
					cursor: pointer;
					padding: 5px;
					color: inherit;
					display: inline-block;
				}
			}
		}

		.orders-col {
			width: 76%;
			padding-left: 10%;
			padding-right: 15px;

			.filters {
				display: flex;
				font-size: 15px;
				margin: 25px 0 20px;

				& > div {
					&.shop {
						margin-right: 25px;
					}

					label {
						margin-right: 8px;
					}

					select {
						background: $grey-bg;
						border: 0;
						padding: 5px;
						border-right: 5px solid $grey-bg;
						border-radius: 3px;
						cursor: pointer;
						font-size: 15px;
					}
				}
			}

			.orders-table-wrapper {
				width: 100%;
				overflow-x: auto;

				.orders-table {
					border-collapse: collapse;
					text-align: center;
					font-size: 13px;
					width: 100%;

					&.saved {
						max-width: 800px;
					}

					th {
						background: $grey-bg;
						padding: 25px;
						font-family: $book-font;
						font-weight: normal;
						z-index: 1;
					}

					td {
						padding: 40px 25px;
					}

					th,
					td {
						border: 1px solid $border-color;
					}

					.edit-order,
					.preview-order {
						padding: 10px;
						display: inline-flex;
					}

					.colStatus {
						.in-progress {
							color: $in-progress;
						}

						.completed {
							color: $completed;
						}

						.canceled {
							color: $canceled;
						}
					}
				}
			}
		}
	}
}
