#breadcrumbs {
	display: flex;

	.bread-item {
		font-size: 25px;
		font-family: $bold-font;

		&:not(:last-child) {
			margin-right: 10px;

			&::after {
				content: '>';
				margin-left: 10px;
				font-family: $book-font;
			}
		}

		a {
			color: inherit;
			font-family: inherit;
			text-decoration: none;
		}
	}
}
