.mega-menu {
	position: fixed;
	top: 74px;
	left: calc((100vw - 1135px) / 2);
	width: auto;
	max-width: 100%;
	margin: auto;
	z-index: 1000;
	background: $white;
	box-shadow: 0px 3px 7px #ebeaea;
	display: flex;
	opacity: 0;
	visibility: hidden;

	&.has-transition {
		transition: 0.2s;
	}

	nav {
		display: flex;
		flex-direction: column;
		font-size: 15px;
	}

	.categories {
		padding: 45px 10px;
		border-right: 1px solid #cecccc;
		text-transform: uppercase;
		width: 200px;

		.nav-link {
			color: $black;
			margin-bottom: 5px;
			text-decoration: none;
			padding: 10px 18px 10px 28px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			&::after {
				content: url('../images/icons/polygon.svg');
				position: absolute;
				right: 0;
				display: none;
			}

			&:hover,
			&.active {
				font-family: $bold-font;

				&::after {
					display: block;
				}
			}

			&.no-childItems {
				&::after {
					display: none;
				}
			}
		}
	}

	#subcategoriesOuter {
		padding: 45px 38px;
		flex-grow: 1;
		display: none;

		.subcategories-wrapper {
			justify-content: space-between;

			.subcategories {
				width: 230px;

				.nav-link {
					color: $black;
					text-decoration: none;
					padding: 5px;

					&:hover {
						font-family: $bold-font;
					}
				}
			}

			.category-image {
				align-self: center;
				width: 367px;
				height: 266px;
				object-fit: cover;
			}
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}

	&.show-subcategories {
		right: calc((100vw - 1135px) / 2);

		#subcategoriesOuter {
			display: block;
		}
	}
}
