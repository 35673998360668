.page {
	padding: 70px 0 100px;

	.page-title {
		font-size: 25px;
		font-family: $bold-font;
		margin: 0;
	}

	.products-table {
		.colImage1 {
			min-width: 130px;
		}

		.colApprovedStock,
		.colChargedStock,
		.colPendingStock {
			.colors-sizes-toggle {
				cursor: pointer;
				white-space: pre-wrap;
				display: flex;
				justify-content: center;
				align-items: center;

				&::after {
					content: '+';
					font-family: $bold-font;
				}

				&.active {
					&::after {
						content: 'x';
						top: -1px;
						position: relative;
					}
				}
			}

			.colors-sizes-cell {
				min-width: 100px;
				width: 100%;
				display: none;

				.color-wrapper {
					cursor: pointer;
				}

				&.show {
					display: block;
				}
			}
		}
	}

	.sub-heading {
		font-size: 17px;
		font-family: $bold-font;
		margin: 0 0 12px;
	}

	&.order-details {
		.sub-heading {
			color: $dark-grey;
		}

		.grid-wrapper {
			margin: 70px auto;
		}

		.order-details-wrapper {
			.row {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.comments-col {
					.comments {
						width: 370px;
						min-height: 170px;
						resize: vertical;
						background: $grey-bg;
						border: 1px solid $border-color;
						padding: 10px;
						font-size: 13px;
						line-height: 16px;
						color: $black;

						&::placeholder {
							color: #787878;
						}
					}
				}

				.order-sum-col {
					display: flex;
					justify-content: flex-end;
					flex-wrap: wrap;
					padding-left: 15px;

					@media (max-width: 1200px) {
						justify-content: flex-start;
					}

					.total,
					.remaining-total {
						font-size: 14px;
						display: flex;
						line-height: 25px;

						.left-col {
							text-align: right;
						}

						.right-col {
							margin-left: 10px;
						}

						p {
							margin: 0;
						}

						.title {
							font-family: $medium-font;
							font-size: 17px;
						}
					}

					.total {
						margin-right: 150px;
					}

					.print-wrapper {
						width: 100%;
						display: flex;
						align-items: center;
						margin-top: 75px;

						.print-btn {
							width: 200px;
							font-size: 13px;
							display: block;
							margin-left: auto;
						}
					}
				}
			}
		}
	}

	&.checkout {
		.checkout-wrapper {
			margin-top: 40px;

			.row {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin: 0 -15px;

				.order-info-col,
				.order-grid-col {
					padding: 0 15px;
				}

				.order-info-col {
					width: 33%;

					.info-wrapper {
						.sub-heading {
							margin-bottom: 20px;
						}

						& > div {
							font-size: 15px;
							margin-bottom: 15px;

							select {
								padding: 5px;
								border: 0;
								margin-left: 4px;
								font-size: 15px;
							}
						}
					}

					.totals-table-wrapper {
						margin-top: 25px;
						overflow-x: auto;

						table {
							width: 100%;
							border-collapse: collapse;

							tr {
								th,
								td {
									border: 1px solid $border-color;
								}

								th {
									font-family: $book-font;
									font-weight: 400;
									font-size: 13px;
									background: #f5f5f6;
									padding: 8px 12px;
									z-index: 1;
								}

								td {
									font-size: 15px;
									padding: 10px 5px;
								}

								&:last-child {
									td {
										font-family: $bold-font;
									}
								}
							}
						}
					}

					.comments-wrapper {
						margin-top: 45px;

						.sub-heading {
							margin-bottom: 20px;
						}

						.comments {
							width: 370px;
							min-height: 170px;
							resize: vertical;
							background: $grey-bg;
							border: 1px solid $border-color;
							padding: 10px;
							font-size: 13px;
							line-height: 16px;
							color: $black;

							&::placeholder {
								color: #787878;
							}
						}
					}
				}

				.order-grid-col {
					width: 67%;
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.grid-wrapper {
						width: 100%;

						h2 {
							display: flex;
							justify-content: space-between;
						}

						.group-select {
							justify-content: center;
						}

						.select__value-container {
							width: 120px;
							padding: 2px 8px;
						}

						.colDelete img {
							cursor: pointer;
						}
					}

					.total-wrapper {
						display: flex;
						margin-top: 50px;
						font-size: 14px;
						line-height: 25px;

						.left-col {
							text-align: right;
						}

						.right-col {
							margin-left: 10px;
						}

						p {
							margin: 0;
						}

						.title {
							font-family: $medium-font;
							font-size: 17px;
						}
					}

					.buttons-wrapper {
						margin-top: 75px;

						.acceptance {
							display: flex;
							align-items: center;

							label {
								font-size: 14px;
								color: $dark-grey;
								margin-left: 10px;
								cursor: pointer;
							}
						}

						.buttons {
							display: flex;
							justify-content: flex-end;
							margin-top: 40px;

							#saveOrderBtn {
								margin-right: 16px;
							}
						}
					}

					.zero-quantity {
						border: 3px solid rgba(255, 47, 47, 0.736);
					}
				}
			}
		}

		#order-uploader {
			align-self: baseline;
			margin-bottom: 20px;
			display: flex;
			gap: 20px;
		}
	}

	&.reset-pass {
		min-height: calc(100vh - 379px);
		display: flex;
		align-items: center;

		.email-sent-wrapper {
			text-align: center;
			max-width: 450px;
			margin: auto;

			.btn {
				margin-top: 20px;
				display: inline-block;
			}
		}

		.reset-pass-form-wrapper {
			max-width: 600px;
			text-align: center;
			margin: auto;

			.reset-pass-request-form,
			.change-pass-form {
				display: flex;
				flex-direction: column;
				justify-content: center;
				max-width: 320px;
				margin: 40px auto 0;

				label {
					font-family: $medium-font;
					margin-bottom: 5px;
				}

				input {
					width: 100%;
					height: 34px;
					padding: 8px;
					margin-bottom: 10px;
					font-size: 13px;
					border: 2px solid $border-color;
					-webkit-appearance: none;
					border-radius: 0;

					&:focus-visible {
						outline: none;
						border-color: $orange;
					}
				}

				.submit-btn {
					font-size: 13px;
					padding: 10px 22px;
					border: 0;
				}
			}
		}
	}

	&.not-found {
		min-height: calc(100vh - 379px);
		display: flex;
		align-items: center;
		text-align: center;

		.btn {
			margin-top: 20px;
			display: inline-block;
		}
	}
}

@media only print {
	.site-header,
	.site-footer,
	.copyrights {
		display: none;
	}

	.page.order-details {
		print-color-adjust: exact;

		.grid-wrapper {
			margin: 40px auto;

			.products-table {
				& > thead {
					& > th {
						padding: 6px;

						&:first-child {
							display: none;
						}
					}
				}

				& > tbody {
					& > td {
						padding: 6px;

						&:first-child {
							display: none;
						}
					}
				}

				.colors-sizes-toggle-cell {
					.colors-sizes-toggle {
						&.active {
							&::after {
								content: none;
							}
						}
					}

					.colors-sizes-cell {
						min-width: 125px;

						.prod-colors {
							width: 100%;

							.color-wrapper {
								width: 49%;
								margin: 0 0 8px !important;

								.color-name {
									margin-bottom: 2px;
									font-size: 8px;
								}

								.color-hex {
									width: 20px;
									height: 20px;
								}
							}
						}
					}
				}

				.colors-sizes-grid {
					thead {
						th {
							padding: 6px 4px;
						}
					}

					.size-th {
						padding: 6px 8px;
					}

					td {
						.color-input {
							padding: 8px 5px;
						}
					}

					.color-wrapper {
						.color-hex {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

		.order-details-wrapper {
			.row {
				flex-direction: column;

				.order-sum-col {
					margin-top: 40px;
					width: 100%;
					padding-left: 0;

					.total {
						margin-right: 0;
					}

					.total,
					.remaining-total {
						width: 50%;
					}

					.print-wrapper {
						display: none;
					}
				}
			}
		}
	}
}

#impersonation-header {
	position: fixed;
	z-index: 2003;
	width: 100%;
	background: #000;
	color: #fff;
	text-align: center;
	padding: 10px 0;
	bottom: 0;
}

#impersonation-header a {
	margin-left: 30px;
	color: #fff;
	font-weight: bold;
}
