.popup-container {
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: 0.1s;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;

	&.show {
		visibility: visible;
		opacity: 1;
	}

	#overlay {
		background: rgba(71, 71, 71, 0.75);
		width: 100%;
		height: 100%;
	}

	div#closePopupBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}

	.popup {
		position: absolute;
		z-index: 1;
		background: #fff;
		padding: 15px;
		width: 450px;

		.popup-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 320px;
			margin: 10px auto 0;
			text-align: center;

			p {
				margin: 0;
				font-size: 13px;
				line-height: 16px;
			}

			#orderNameField {
				width: 290px;
				height: 35px;
				background: #fff;
				border: 1px solid #000;
				margin-top: 20px;
				padding: 4px;
			}

			.btn {
				margin: 25px auto 10px;
				width: 165px;
			}
		}
	}
}
