.site-footer {
	background-color: $dark-grey;
	color: $white;
	padding: 60px 0 20px;

	.row {
		display: flex;
		margin: 0 -15px;

		.col {
			padding: 0 15px;

			&.nav-col {
				margin-right: 10%;
			}
		}
	}

	.title {
		text-transform: uppercase;
		font-family: $bold-font;
		font-size: 25px;
		margin: 0 0 15px;
	}

	.footer-nav {
		font-size: 13px;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		a {
			margin-bottom: 10px;
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.footer-contact {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		a {
			margin-bottom: 10px;
			color: inherit;
			text-decoration: none;
			display: flex;
			align-items: center;

			&:hover {
				text-decoration: underline;
			}

			.icon {
				margin-right: 6px;
			}
		}
	}
}

.copyrights {
	height: 50px;
	color: $white;
	background: $black;
	display: flex;
	align-items: center;
	font-size: 11px;
}
