.home-carousel {
	.swiper-slide {
		display: flex;
		justify-content: center;

		a {
			width: 100%;
			max-height: calc(100vh - $header-height);
			object-fit: cover;
			display: block;

			img {
				height: 100%;
				width: 100%;
			}
		}

		.text {
		}
	}

	.swiper-pagination {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 50px;
		z-index: 1;
		left: 0;
		right: 0;

		.swiper-pagination-bullet {
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			margin: 0 8px;
			background: $white;
			cursor: pointer;

			&.swiper-pagination-bullet-active {
				background: $orange;
			}
		}
	}
}
