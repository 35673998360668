.products-table-wrapper {
	.products-table {
		border-collapse: collapse;
		width: 100%;

		th,
		td {
			border: 1px solid $border-color;

			&:nth-child(12) {
				border-left: 3px solid $border-color;
			}
		}

		th {
			font-size: 13px;
			text-align: center;
			padding: 10px;
			font-family: $book-font;
			font-weight: normal;
			background: $grey-bg;
			z-index: 1;

			.images-header-wrapper {
				display: flex;
				align-items: center;
				padding-left: 27px;
				position: relative;

				.icon {
					position: absolute;
					cursor: pointer;
					padding: 5px;
					left: 0;
				}
			}
		}

		td {
			padding: 10px;
			font-size: 13px;
			text-align: center;

			&:nth-child(2) {
				text-align: left;
			}
		}

		tbody {
			tr {
				height: 1px;

				td {
					height: inherit;
				}

				.colImage1 img {
					width: 105px;
					height: 100px;
				}

				.colColorSize {
					&.show {
						border-bottom: 1px solid $white;
					}
				}
			}
		}

		.col-size-header-wrapper {
			position: relative;

			.tooltip {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				bottom: calc(100% + 24px);
				left: 85px;
				width: 220px;
				z-index: 1;
				background: $white;
				padding: 12px 20px;
				box-shadow: 0px 3px 6px #00000029;
				text-align: left;
				border-radius: 3px;
				font-size: 11px;

				& > div {
					display: flex;
					align-items: center;

					.color-box {
						width: 20px;
						height: 12px;
						display: block;
						border: 1px solid $color-box-border;
						margin-right: 10px;
						display: flex;
						align-items: center;
						justify-content: center;

						&.out-of-stock {
							background: $out-of-stock;
						}

						&.limited-stock {
							background: $limited-stock;
						}

						&.available {
							background: $available;
						}

						&.available-soon {
							background: $available-soon;
						}

						&.not-available {
							background: $not-available;
						}
					}

					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}
			}

			&:hover {
				.tooltip {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		.colors-sizes-toggle-cell {
			.colors-sizes-toggle {
				cursor: pointer;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: pre-wrap;

				&::after {
					content: '+';
					font-family: $bold-font;
				}

				&.active {
					&::after {
						content: 'x';
						top: -1px;
						position: relative;
					}
				}
			}
		}

		.colors-sizes-row {
			display: none;

			&.show {
				display: table-row;
			}

			td {
				padding: 30px;
			}

			.colors-sizes-cell {
				.prod-colors {
					.color-wrapper {
						.color-name {
							font-size: 11px;
						}
					}
				}
			}
		}

		.colors-sizes-cell {
			.prod-colors {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				.color-input {
					&.out-of-stock {
						background: $out-of-stock;
					}

					&.not-available {
						background: $not-available;
					}

					&.limited-stock {
						background: $limited-stock;
					}

					&.available {
						background: $available;
					}

					&.available-soon {
						background: $available-soon;
					}
				}
			}
		}

		.colors-sizes-grid,
		.colors-sizes-cell .prod-colors {
			.color-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				margin: 5px 10px 10px;

				.color-name {
					font-size: 9px;
					color: $dark-grey;
					margin-bottom: 5px;

					div {
						color: #000;
					}
				}

				.color-hex {
					width: 30px;
					height: 30px;
					border: 3px solid $white;
					position: relative;
					margin-bottom: 5px;

					&::after {
						content: '';
						border: 1px solid $border-color;
						display: block;
						position: absolute;
						left: -3px;
						top: -3px;
						right: -3px;
						bottom: -3px;
					}
				}

				&:focus-within {
					.color-hex:after {
						border: 1px solid #000;
					}
				}
			}

			.color-input {
				width: 35px;
				height: 20px;
				font-size: 13px;
				text-align: center;
				border: 1px solid $border-color;
				/* Chrome, Safari, Edge, Opera */
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
			/* Firefox */
			input[type='number'] {
				-moz-appearance: textfield;
			}
		}

		.colors-sizes-toggle-cell {
			.colors-sizes-cell {
				&.simple-input-cell {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}

				.prod-colors {
					justify-content: flex-start;
					align-items: baseline;

					.color-wrapper {
						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							margin-bottom: 0;
						}
					}

					@media (max-width: 1420px) {
						justify-content: center;
					}
				}
			}
		}

		.colors-sizes-grid-wrapper {
			overflow-x: auto;

			.colors-sizes-grid {
				max-width: 100%;
				margin: auto;
				border-collapse: collapse;

				th,
				td {
					border-color: $black;
					background: $white;
					text-align: center;
					position: inherit;
				}

				thead {
					th {
						padding: 24px 4px 12px;
						max-width: 120px;
						word-break: break-word;

						.color-wrapper {
							margin: 0;
						}
					}
				}

				td {
					padding: 0;
					max-width: 120px;

					&.out-of-stock {
						background: $out-of-stock;
					}

					&.not-available {
						background: $not-available;

						&::after {
							content: 'x';
							font-size: 16px;
						}
					}

					&.limited-stock {
						background: $limited-stock;
					}

					&.available {
						background: $available;
					}

					&.available-soon {
						background: $available-soon;
					}

					.color-input {
						width: 100%;
						height: 100%;
						padding: 10px 5px;
						background: transparent;
						border: 0;

						&.out-of-stock {
							background: $out-of-stock;
						}

						&.not-available {
							background: $not-available;
						}

						&.limited-stock {
							background: $limited-stock;
						}

						&.available {
							background: $available;
						}

						&.available-soon {
							background: $available-soon;
						}
					}
				}

				.size-th {
					padding: 8px 15px;
					font-size: 14px;
					font-family: $medium-font;
					text-align: left;
				}
			}
		}
	}
}
