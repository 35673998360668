.order-created-wrapper {
	width: 650px;
	margin: auto;
	max-width: 100%;
	min-height: calc(100vh - 379px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 15px 2%;
	text-align: center;

	.pencil-icon {
		margin-bottom: 35px;
	}

	.title {
		font-size: 24px;
		font-family: $medium-font;
		font-weight: 400;
		margin: 0 0 5px;
	}

	.description {
		font-size: 16px;
		margin: 0;
	}

	.btn {
		margin-top: 35px;
	}
}
